<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        Açık Hatırlatmalar
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          v-b-tooltip.hover.v-primary.left
          title="Süresi dolmuş hatırlatmalar, en yakın 10 hatırlatma"
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      hover
      small
    >
      <template #cell(customer)="data">
        <div
          class="cursor-pointer"
          @click="$router.push('/app/interviews/view/' + data.item.id)"
        >
          <div class="font-weight-bold">
            {{ data.item.customer }}
          </div>
          <div
            v-if="data.item.outdated === '1'"
            class="text-danger font-italic font-small-1"
          >
            Hatırlatma {{ moment().diff(moment(data.item.remind),'days') }} gün gecikmiş.
          </div>
          <div
            v-else
            class="text-muted font-italic font-small-1"
          >
            Hatırlatma {{ moment(data.item.remind).diff(moment(),'days') }} gün kaldı.
          </div>
        </div>
      </template>
      <template #cell(remind)="data">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="$router.push('/app/interviews/view/' + data.item.id)"
        >
          <div class="mr-1">
            <FeatherIcon
              icon="ClockIcon"
              size="18"
            />
          </div>
          <div>
            <div
              class="text-nowrap pr-1"
              :class="data.item.outdated === '1'? 'text-danger' : 'text-success'"
            >
              {{ moment(data.item.remind).format('ll') }}
            </div>
            <div class="text-muted text-nowrap pr-1">
              {{ data.item.username }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardText, BTable, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'Reminders',
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
          tdClass: 'font-small-2',
        },
        {
          key: 'remind',
          label: '',
          thClass: 'width-50 text-left',
          tdClass: 'font-small-2  text-left',
        },
      ],
    }
  },
}
</script>
