<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        Açık Teklifler
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          v-b-tooltip.hover.v-primary.left
          title="Son 15 teklif"
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      hover
      small
    >
      <template #cell(customer)="data">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="$router.push('/app/offers/view/' + data.item.id)"
        >
          <div>
            <div>
              {{ data.item.customer }}
            </div>
            <div class="font-small-2 text-warning">
              {{ data.item.offer_status }}
            </div>
            <div
              v-if="data.item.odate"
              class="text-muted font-small-2"
            >
              Teklif Tarihi: {{ moment(data.item.odate).format('ll') }}
            </div>
            <div
              class="text-muted font-small-2"
            >
              Geçerlilik Tarihi: {{ data.item.vdate? moment(data.item.vdate).format('ll') : 'Belirtilmemiş' }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(username)="data">
        <div
          class="cursor-pointer"
          @click="$router.push('/app/offers/view/' + data.item.id)"
        >
          <div>
            {{ data.item.username }}
          </div>
          <div
            v-if="data.item.vdate"
          >
            <span
              v-if="moment(data.item.vdate).diff(moment(),'days') > 0"
              class="text-warning"
            >
              {{ moment(data.item.vdate).diff(moment(),'days') }} gün kaldı.
            </span>
            <span
              v-else
              class="text-danger"
            >
              Süre doldu.
            </span>
          </div>
          <div
            v-else
            class="text-danger"
          >
            Süre Belirtilmemiş.
          </div>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardText, BTable, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'Offers',
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
          tdClass: 'font-small-2',
        },
        {
          key: 'username',
          label: 'TEMSİLCİ',
          tdClass: 'font-small-2',
        },
      ],
    }
  },
}
</script>
